import { Image } from '~/components/Image'
import ImageOverlayLinks from '~/components/ImageOverlayLinks/ImageOverlayLinks'
import { getImageSrc, getImageSrcSet } from '~/helpers'
import { PromoBannerBackgroundImagesProps } from '~/sections/PromoBanner/PromoBanner.types'

const PromoBannerBackgroundImage = ({
  desktop,
  tablet,
  mobile,
  renderLinks,
}: PromoBannerBackgroundImagesProps) => {
  const mobileImage = mobile || tablet || desktop
  const tabletImage = tablet || desktop || mobile
  const desktopImage = desktop || tablet || mobile

  return (
    <>
      {desktopImage?.src && (
        <>
          {renderLinks && (
            <ImageOverlayLinks
              desktopImage={desktopImage}
              tabletImage={tabletImage}
              mobileImage={mobileImage}
            />
          )}
          <Image
            className='h-full w-full'
            src={getImageSrc(desktopImage.src, '1200')}
            addSrcSet={false}
            alt={desktopImage.altText || ''}
            width={desktopImage.width}
            height={desktopImage.height}
            sizes='(max-width: 1023) 0px, (min-width: 1024) 1064px'
            style={{ objectFit: 'contain' }}
            sources={
              <>
                <source
                  srcSet={getImageSrcSet(mobileImage?.src, [
                    {
                      intrinsicImageSize: '800',
                      width: '800',
                    },
                  ])}
                  media='(max-width: 768px)'
                />
                <source
                  srcSet={getImageSrcSet(tabletImage?.src, [
                    {
                      intrinsicImageSize: '1100',
                      width: '1100',
                    },
                  ])}
                  media='(max-width: 1024px)'
                />
              </>
            }
          />
        </>
      )}
      {!desktopImage?.src && null}
    </>
  )
}

export default PromoBannerBackgroundImage
