import { useEffect } from 'react'
import { Link } from '@overdose/components'
import { IconArrowRight } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from '~/components/Button'
import { Image } from '~/components/Image'
import RichText from '~/components/RichText/RichText'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import { NEW_TAB_LINK_TARGET } from '~/constants/links'
import { PromoBannerProps } from '~/sections/PromoBanner'
import PromoBannerBackgroundImage from '~/sections/PromoBanner/PromoBannerBackgroundImages'
import styles from './PromoBanner.module.css'

export const PromoBanner = ({
  icon,
  description,
  action,
  width = 'medium',
  toggleOffGradient = false,
  invert = false,
  background,
}: PromoBannerProps) => {
  const hasIcon = !!icon?.src
  const areIconAndButtonVisible = hasIcon && action
  const areIconAndButtonHidden = !hasIcon && !action
  const isOnlyIconHidden = !hasIcon && action
  const bannerLinkUrl = action?.isDisabled ? null : action?.href
  const bannerLinkTarget = action?.openInNewTab ? NEW_TAB_LINK_TARGET : null

  // Ensure clicking on the banner links will scroll it into view
  useEffect(() => {
    document.querySelector('html').classList.add('scroll-padTop')

    return () => {
      document.querySelector('html').classList.remove('scroll-padTop')
    }
  }, [])

  return (
    <SectionContainer width={width}>
      <div
        className={classNames(
          'relative',
          styles.wrapper,
          areIconAndButtonVisible && styles.iconAndButtonVisibleStyles,
          areIconAndButtonHidden && styles.iconAndButtonHiddenStyles,
          isOnlyIconHidden && styles.iconOnlyHiddenStyles
        )}>
        <PromoBannerBackgroundImage
          {...background}
          renderLinks={!bannerLinkUrl}
        />
        {bannerLinkUrl && (
          <Link
            className='absolute z-10 inset-0'
            to={bannerLinkUrl}
            target={bannerLinkTarget}
          />
        )}
        <div
          className={classNames(
            'absolute top-0 left-0 w-full h-full flex flex-col items-baseline p-6',
            styles.contentContainer,
            {
              'lg:px-10 3xl:pt-10 3xl:pb-8': hasIcon,
              [styles.gradient]: !toggleOffGradient,
              [styles.contentWhite]: !invert,
            }
          )}>
          {hasIcon && (
            <div className={styles.icon}>
              <Image
                src={getImageSrc(icon.src, '200')}
                alt={icon.alt}
                width={icon.width}
                height={icon.height}
                className='h-full w-full'
                style={{ objectFit: 'contain', objectPosition: 'left' }}
              />
            </div>
          )}
          {description && (
            <div
              className={classNames('hidden', {
                '!block': areIconAndButtonVisible,
              })}>
              <RichText content={description.richText} />
            </div>
          )}
          {action?.title && !action?.isDisabled && (
            <Button
              status='success'
              style={{
                backgroundColor: action?.color ?? null,
              }}
              theme={{
                root: classNames(
                  styles.action,
                  isOnlyIconHidden && styles.iconOnlyBtn
                ),
              }}
              ariaLabel={action.title}
              icon={
                <IconArrowRight
                  style={{
                    color: action?.textColor ?? null,
                  }}
                  size={20}
                  className={styles.iconSize}
                />
              }
              iconAlignment='right'>
              <div className='3xl:hidden'>
                <Typography
                  style={{
                    color: action?.textColor ?? null,
                  }}
                  tag={TypographyTag.span}
                  variant={TypographyVariant.BodyRegularBold}>
                  {action.title}
                </Typography>
              </div>
              <div className='hidden 3xl:block'>
                <Typography
                  tag={TypographyTag.span}
                  variant={TypographyVariant.BodyLarge}>
                  {action.title}
                </Typography>
              </div>
            </Button>
          )}
        </div>
      </div>
    </SectionContainer>
  )
}
